.global-footer {
    @include make-row();
    border-top: 1px solid $grey-light-alt;
    background-color: $grey;
    .copyright-info {
        @include make-col-ready;
        @include make-col(12);
        padding: spacing(md) spacing(xxl)+2;
        @include max-screen(sm) {
            padding: spacing(xs) spacing(xxs);
        }
        .content-wrapper {
            margin-bottom: 0;
            .copyright {
                display: inline-block;
                margin-right: spacing(md);
                color: $grey-dark-alt-text;
                font-size: font-size(body-lg);
            }
            .footer-links {
                display: inline-block;
                color: $grey-dark-alt-text;
                font-size: font-size(body-base);
                &:after {
                    content: '|';
                    display: inline-block;
                    margin: 0 spacing(xxs) 0 spacing(xs);
                }
                &:last-child {
                    &:after {
                        content: '';
                        margin: 0;
                    }
                }
            }
        }
    }
}