.bbt-pageheader {
    height: auto;
    background-color: $darkblue-update;
    h1{
        background-color: transparent;
        padding:20px 0 20px 0px;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 36px */
        text-transform: capitalize;
        flex: initial;
        width: 90%;
        display: inline-block;
        @media (max-width: 768px) {
            margin-left: 15px;
            width: 100%;
         }
         //tablets
         @media (min-width: 769px) and (max-width: 1400px){
            margin-left: 36px;
         }
    }
    @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
     }

}
.bbt-services-landing{
    margin-top: initial !important;

    .service-link{
        color: $color-brand-black !important;
        font-family: Poppins !important;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 140% !important; /* 28px */
    
        &:hover{
            text-decoration: none !important;
        }

    }
}

#bbt-service-listings {
    margin-top: initial;
    margin-left: initial;
    flex: 0 0 72%;
    max-width: 100%;
    padding-top: 73px;
    padding-left: 35px;
    @media (max-width: 768px) {
        padding-top: 10px;
        padding-left: 16px;
        flex: 0 0 100%;
     }


    .bbt-services-search{
        padding: initial;
        margin-left: initial;
        @media (max-width: 768px) {
            margin-top: 0;
            margin-bottom: 0;
         }
    }
    .service-content {
        margin-left: initial;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .people-results-options {
        border-bottom: none;
        margin-bottom: 40px;
    }
    .global-main-content {
        padding: initial;
        border-bottom: 1px solid #D3D5DA;
        .service-search-results-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding-bottom: 40px;
            .servicesearchresults{
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
                margin-top: initial;
                .content-block-label {
                    color: $color-brand-black;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 28px */
                }
                .service-landing-link{
                    padding-left: initial;
                    color: $darkblue-update;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 28px */
                    text-decoration-line: underline;
                }
                ul.list{
                    margin-top: initial;
                    li {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
    .bbt-services-search-box {
        flex: 0 0 100%;
        max-width: 100%;
        .servicesearchbtn {
            position: initial;
            background-color: $darkblue-update;
            color: #FFF;
            padding: 17px;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 18px */

        }
        .keyword-search {
            display: inline-flex;
            width: 100%;
            @media (max-width: 768px) {
             flex-direction: column;
             padding-right: 30px;
             }
            .servicesearch {
                margin-right: 16px;
                width: 88%;
                @media (max-width: 768px) {
                   width: 100%;
                   margin-bottom: 20px;
                    }
            }
        }
    }
}
.bbt-main {
    justify-content: space-between;
    padding: 0 52px 50px;
}

.bbt-mainN{
    display: flex;
}
.bbt-infocallout {
    border-image: linear-gradient(90deg, rgba(255, 255, 255, 0.50) 0%, rgba(217, 217, 217, 0.50) 100%) 10 round;
    border-right: 16px solid transparent;
    padding-top: 40px;
}

