.bbt-listings {
    // @include make-col-ready();
    @include make-col-offset(1.25);
    @include make-col(7.75);
    margin-top: -99px;
    padding: 0;

    @include max-screen(sm) {
        @include make-col-offset(0);
        @include make-col(12);
        margin-top: 0;
        order: 1;
    }

    .sector-utility-bar {
        h1 {
            margin: 0 0 17px;
            color: $grey-dark;
        }

        a {
            @extend .bbt-view-more !optional;
            margin-right: spacing(xl);
        }
    }

    .sector-tabs {
        h1 {
            margin: 34px 0 44px;
            color: $grey-dark;

            @include max-screen(sm) {
                margin-bottom: 21px;
            }
        }

        .sector-tab-view {
            @include make-row();
            margin: 0;

            .service-navigation {
                @include make-col-ready();
                @include make-col(4);
                border-right: 1px solid $grey-border;
                padding: 0;

                @include max-screen(sm) {
                    display: none;
                }

                ul {
                    @include remove-list-format();
                    margin-left: -28px;

                    li {
                        padding: spacing(sm)+1 spacing(md)+5;
                        position: relative;

                        a {
                            color: $grey-dark-text;
                            font-size: font-size(service-nav);
                            font-weight: font-weight(medium);
                        }

                        &.selected {
                            background-color: $red;

                            a {
                                color: $color-brand-white;
                            }

                            &:after {
                                position: absolute;
                                right: 20px;
                                content: '\f105';
                                color: $color-brand-white;
                                font-family: 'FontAwesome';
                                font-size: font-size(service-nav);
                            }
                        }
                    }
                }
            }

            .service-navigation-mobile {
                display: none;

                @include max-screen(sm) {
                    @include make-row();
                    width: 100%;
                    margin: 0;
                }

                .bbt-select {
                    @include make-col-ready();
                    @include make-col(12);
                    padding: 0;
                    position: relative;

                    select {
                        display: none;
                        /*hide original SELECT element:*/
                    }

                    .select-selected {
                        border: 1px solid $grey-border;
                        padding: 8px 16px;
                        background-color: $color-brand-white;
                        color: $grey-dark-text;
                        font-size: font-size(body-md);
                        font-weight: font-weight(bold);
                        cursor: pointer;
                        user-select: none;

                        /*style the arrow inside the select element:*/
                        &:after {
                            position: absolute;
                            content: "";
                            top: 14px;
                            right: 16px;
                            border-right: 2px solid $grey-dark-text;
                            border-bottom: 2px solid $grey-dark-text;
                            width: 10px;
                            height: 10px;
                            transform: rotate(45deg);
                        }

                        /*point the arrow upwards when the select box is open (active):*/
                        &.select-arrow-active {
                            &:after {
                                top: 18px;
                                transform: rotate(225deg);
                            }
                        }
                    }

                    /*style the items (options), including the selected item:*/
                    .select-items div {
                        color: $grey-dark-text;
                        padding: 8px 16px;
                        border: 1px solid transparent;
                        border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
                        cursor: pointer;
                        user-select: none;

                        &.same-as-selected {
                            color: $color-brand-white;
                            background-color: $red;
                        }
                    }

                    /*style items (options):*/
                    .select-items {
                        position: absolute;
                        background-color: $color-brand-white;
                        top: 100%;
                        left: 0;
                        right: 0;
                        z-index: 99;

                        /*hide the items when the select box is closed:*/
                        &.select-hide {
                            display: none;
                        }
                    }
                }
            }

            .service-content {
                @include make-col-ready();
                @include make-col-offset(0.5);
                @include make-col(7.5);
                padding: 0;
                padding-top: spacing(sm)+1;

                @include max-screen(sm) {
                    @include make-col-offset(0);
                    @include make-col(12);
                }

                h2 {
                    margin-bottom: spacing(sm);

                    a {
                        font-size: font-size(heading-two)-2;
                        color: $cyan;
                        transition: color .25s ease-in, background-color .25s ease-in;

                        &:hover {
                            color: $grey-dark;
                            text-decoration: none;
                        }
                    }
                }

                p {
                    margin-bottom: spacing(md);
                    padding-bottom: spacing(lg);
                    color: $grey-dark-text;
                    font-size: font-size(body-md);
                    font-weight: font-weight(medium);
                    border-bottom: 1px solid $grey-border-alt;
                }

                ul {
                    @include remove-list-format();
                    @include make-row;

                    li {
                        @include make-col-ready();
                        @include make-col(6);
                        margin-bottom: spacing(md);

                        @include max-screen(sm) {
                            @include make-col(12);
                        }

                        a {
                            @extend .bbt-view-more !optional;
                        }
                    }
                }
            }
        }
    }
}