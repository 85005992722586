﻿@import 'functions';
//---------- FONT SIZES
//----------------------------------------------
@mixin font-size-body-base() {
    font-size: font-size(body-base);
}

@mixin font-size-body-sm() {
    font-size: font-size(body-sm);
}

@mixin font-size-body-md() {
    font-size: font-size(body-md);
}

@mixin font-size-body-lg() {
    font-size: font-size(body-lg);
}

@mixin font-size-page-title() {
    font-size: font-size(page-title);
}

@mixin font-size-heading-one() {
    font-size: font-size(heading-one);
}

@mixin font-size-heading-two() {
    font-size: font-size(heading-two);
}

@mixin font-size-heading-three() {
    font-size: font-size(heading-three);
}

@mixin font-size-heading-four() {
    font-size: font-size(heading-four);
}

@mixin font-size-heading-five() {
    font-size: font-size(heading-five);
}

//---------- FONT WEIGHTS
//----------------------------------------------
@mixin font-weight-bold() {
    font-weight: font-weight(bold);
}

@mixin font-weight-medium() {
    font-weight: font-weight(medium);
}

@mixin font-weight-regular() {
    font-weight: font-weight(regular);
}

@mixin font-weight-light() {
    font-weight: font-weight(light);
}

//---------- TYPOGRAPHY
//----------------------------------------------
@mixin page-title() {
    @include font-weight-bold;
    @include font-size-page-title;
    font-family: $font-family-regular;
    color: $color-brand-white;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}
@mixin heading-one() {
    @include font-weight-bold;
    @include font-size-heading-one;
    font-family: $font-family-regular;
    color: $color-brand-black;
}

@mixin heading-two() {
    @include font-weight-bold;
    @include font-size-heading-two;
    font-family: $font-family-regular;
    color: $grey-dark;
    letter-spacing: 0.5px;
}

@mixin heading-three() {
    @include font-weight-bold;
    @include font-size-heading-three;
    font-family: $font-family-regular;
    color: $grey-dark;
}

@mixin heading-four() {
    @include font-weight-bold;
    @include font-size-heading-four;
    font-family: $font-family-regular;
    color: $greyer;
}

@mixin heading-five() {}

@mixin heading-six() {}

//---------- HELPERS
//----------------------------------------------
@mixin remove-list-format() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin remove-button-styles() {
    background: none;
    border: none;
    outline: none;
    margin: 0;
    min-width: 0; //sharepoint has a min width
    padding: 0;
}

@mixin flex-v-align() {
    display: flex;
    align-items: center;
}

//---------- MEDIA QUERIES
//----------------------------------------------
// Min-width
@mixin min-screen($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint); // If the key exists in the map
    @if $value !=null {
        // Prints a media query based on the value
        @media (min-width: $value) {
            @content;
        }
    } // If the key doesn't exist in the map
    @else {
        @warn "No value could be retrieved from `#{$breakpoint}`. "+"Make sure it is defined in `$breakpoints` map.";
    }
}

// Max-width
@mixin max-screen($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint); // If the key exists in the map
    @if $value !=null {
        // Prints a media query based on the value
        @media (max-width: $value) {
            @content;
        }
    } // If the key doesn't exist in the map
    @else {
        @warn "No value could be retrieved from `#{$breakpoint}`. "+"Make sure it is defined in `$breakpoints` map.";
    }
}