.service-people-contacts {
    background-color: white;



    .related-contacts {
        margin-top: 0;
        li {
            width: 100%;
            display: flex;
            flex-direction: column;
            color: $color-brand-black;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 25.2px */
            margin-top: 0;
            margin-bottom: 16px;
            .service-contact-details{
                display: flex;
                flex-direction: column;
                
            }
            .contact-name {
                color:  $color-brand-black;
                margin-top: 16px;
            }
            .contact-pos {
                font-weight: 400;
                width: 70%;
                margin-top: 5px;
            }
            .contact-email{
                color: $darkblue-update;
                font-size: 14px;
                font-weight: 400;

            }
            .contact-img {
                margin-right: initial;
                border: none;
                max-width: initial;
                width: 160px;
                 float: initial;
                 margin-right: initial;
                 border: none;
                 max-width: initial;
                 height: auto !important;
            }
        }
    }
    .slick-dots {
        display: flex;
    }
    
}

.bio-details-text {
    padding-top: 10px;
    padding-bottom: 10px;
    @media(min-width: 768px){
        padding-top: 20px;
    padding-bottom: 20px;
     }
}
.related-accordion-content{
    a{
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        color: $color-brand-black;
        display: block;

    }
}
.bio-details-navholder {
    margin-bottom: 20px;
    @media(min-width: 768px){
        margin-bottom: 26px;
     }
}

#bioOverViewText{
    padding-top: 20px;
     @media (min-width: 768px) {
        // padding-top: 62px;
     }
 }

.bio-details-content.services-detail {
    @media (max-width: 768px) {
       // padding-top: 94px;
    }

}
/*to be removed to the main */
.bbt-footer {
position: absolute;
width: 100%;
}