.global-wrapper {
    padding-top: 0;

    > header {
        @include make-container();
        max-width: 1400px;
        margin: 0 auto;

        .bbt-desktop {
            .bbt-top-menu-container {
                margin: 13px 0 0;
            }
        }

    }
}

header {
    .bbt-desktop {
	
        @include max-screen(md) {
            display: none;
        }

        .bbt-top-menu-container {
            @include make-row();
            margin-top: 13px;
            padding: 0 spacing(xxl)+3;

            .bbt-top-menu {
                @include make-col-ready();
                @include make-col(6);
                @include make-col-offset(6);
                padding: 0;
                text-align: right;

                ul {
                    @include remove-list-format();
                    display: inline-block;

                    li {
                        display: inline-block;
                        position: relative;

                        .dropdown {
                            position: absolute;
                            display: none;
                            z-index: 1510;
                            padding-top: 24px;
                            left: 50%;
                            transform: translate(-50%, 0);
                            text-align: left;

                            .dd-content {
                                position: relative;
                                border: 2px solid $grey-dark-text;
                                padding: 24px 50px;
                                background-color: $grey-light;

                                &:before {
                                    display: block;
                                    content: '';
                                    position: absolute;
                                    top: -24px;
                                    left: 50%;
                                    transform: translate(-50%, 0);
                                    border-left: 22px solid transparent;
                                    border-right: 22px solid transparent;
                                    border-bottom: 22px solid $grey-dark-text;
                                }

                                &:after {
                                    display: block;
                                    content: '';
                                    position: absolute;
                                    top: -21px;
                                    left: 50%;
                                    transform: translate(-50%, 0);
                                    border-left: 22px solid transparent;
                                    border-right: 22px solid transparent;
                                    border-bottom: 22px solid $grey-light;
                                }

                                &.has-columns {
                                    @include make-row();
                                    width: 650px;
                                    margin: 0;

                                    .dd-content-column {
                                        display: inline-block;
                                        padding: 0 50px 0 0;

                                        &:last-child {
                                            padding: 0;
                                        }

                                        .bbt-office-group {
                                            margin-bottom: 30px;
                                            white-space: nowrap;

                                            h4 {
                                                margin-bottom: 12px;

                                                a {
                                                    font-size: font-size(body-lg);
                                                }
                                            }

                                            a {
                                                display: block;
                                                margin-bottom: 15px;
                                                color: $cyan;
                                                font-size: font-size(body-base);
                                                font-weight: font-weight(bold);
                                                text-transform: none;
                                            }
                                        }
                                    }
                                }

                                ul {
                                    min-width: 130px;

                                    li {
                                        display: block;
                                        margin-bottom: 15px;

                                        a {
                                            color: $cyan;
                                            font-size: font-size(body-base);
                                            font-weight: font-weight(bold);
                                            text-transform: none;
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            .dropdown {
                                display: block;
                            }
                        }
                    }

                    &.bbt-top-menu-links {
                        li {
                            margin-right: 25px;

                            a {
                                color: $grey-dark-text;
                                font-size: font-size(body-base);
                                font-weight: font-weight(medium);
                                text-transform: uppercase;
                            }
                        }
                    }

                    &.bbt-top-menu-social {
                        li {
                            margin-right: 25px;

                            &:last-child {
                                margin-right: 0;
                            }

                            a {
                                color: $grey-dark-text;
                                font-size: font-size(body-lg);
                                font-weight: font-weight(medium);
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }

        nav {
            @include make-row();
            padding: 0 spacing(xxl)+3;
            margin-bottom: 22px;

            .bbt-logo {
                @include make-col-ready();
                @include make-col(2.5);
                padding: 0 40px 0 0;
                top: -8px;
            }

            ul.bbt-nav {
                @include make-col-ready();
                @include make-col(7);
                @include remove-list-format();
                padding: 40px 0 0;
                z-index: 1500;

                li {
                    position: relative;
                    display: inline-block;
                    margin-right: 40px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        color: $grey-dark;
                        font-size: font-size(body-md);
                        font-weight: font-weight(bold);
                        text-transform: uppercase;
                        text-decoration: none;
                    }

                    .dropdown {
                        position: absolute;
                        display: none;
                        z-index: 100;
                        padding-top: 28px;
                        left: 35%;
                        transform: translate(-35%, 0);
                        text-align: left;

                        .dd-content {
                            position: relative;
                            border: 2px solid $grey-dark-text;
                            padding: 24px 50px;
                            background-color: $grey-light;

                            &:before {
                                display: block;
                                content: '';
                                position: absolute;
                                top: -24px;
                                left: 35%;
                                transform: translate(-35%, 0);
                                border-left: 22px solid transparent;
                                border-right: 22px solid transparent;
                                border-bottom: 22px solid $grey-dark-text;
                            }

                            &:after {
                                display: block;
                                content: '';
                                position: absolute;
                                top: -21px;
                                left: 35%;
                                transform: translate(-35%, 0);
                                border-left: 22px solid transparent;
                                border-right: 22px solid transparent;
                                border-bottom: 22px solid $grey-light;
                            }

                            &.has-columns {
                                @include make-row();
                                width: 650px;
                                margin: 0;

                                .dd-content-column {
                                    display: inline-block;
                                    padding: 0 50px 0 0;

                                    &:last-child {
                                        padding: 0;
                                    }
                                }
                            }

                            ul {
                                min-width: 130px;
                                padding: 0;

                                li {
                                    display: block;
                                    margin-bottom: 15px;

                                    a {
                                        color: $cyan;
                                        font-size: font-size(body-base);
                                        font-weight: font-weight(bold);
                                        text-transform: none;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        >a {
                            color: $cyan;
                        }

                        .dropdown {
                            display: block;
                        }
                    }

                    &.bbt-people-nav {
                        .dropdown {
                            .dd-content {
                                width: 470px;

                                .bbt-search {
                                    @include make-col(12);
                                    margin: 0 0 30px;
                                }

                                .bbt-letter-grid {
                                    @include make-row();
                                    margin: 0;

                                    .bbt-grid-label {
                                        @include make-col-ready;
                                        @include make-col(4);
                                        margin: 0;
                                        padding: 0 25px 0 0;
                                        color: $grey-dark;
                                        font-size: font-size(body-md);
                                        font-weight: font-weight(bold)''
                                    }

                                    ul {
                                        @include make-col-ready;
                                        @include make-col(8);
                                        margin: 0;
                                        padding: 0;

                                        li {
                                            display: inline-block;
                                            margin: 0 0 15px;
                                            padding: 0 4px 0 0;

                                            a {
                                                font-size: font-size(body-md);
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }

                    &.bbt-experience-nav {
                        .dd-content {
                            width: 740px;

                            .dd-content-column-group {
                                @include make-row();
                                justify-content: space-between;
                                margin: 0;

                                .dd-content-column {
                                    @include make-col-ready;
                                    @include make-col(2.5);
                                    padding: 0;

                                    &.larger {
                                        @include make-col(5);
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: space-between;

                                        h4 {
                                            width: 100%;
                                            position: relative;

                                            >a {
                                                position: absolute;
                                                top: 6px;
                                                right: 0;
                                                color: $cyan;
                                                font-size: font-size(body-sm);
                                            }
                                        }

                                        >a {
                                            @include make-col-ready();
                                            @include make-col(6);
                                            padding: 0;
                                        }
                                    }

                                    a {
                                        display: block;
                                        margin-bottom: 20px;
                                        color: $cyan;
                                        font-size: font-size(body-base);
                                        font-weight: font-weight(bold);
                                        text-transform: none;
                                    }

                                    &:last-child {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }

                    &.bbt-services-nav {
                        .dd-content {
                            width: 780px;

                            .bbt-listings {
                                @include make-col-offset(0);
                                @include make-col(12);
                                margin: 0;
                                padding: 0;

                                .sector-utility-bar {
                                    margin-bottom: 50px;

                                    a {
                                        color: $cyan;
                                        font-size: font-size(body-md);
                                        text-transform: none;
                                    }
                                }

                                .sector-tabs {
                                    ul {
                                        margin: 0;
                                        padding: 0;

                                        &.service-list {
                                            margin-left: -26px;
                                        }

                                        >li {
                                            margin: 0;
                                            padding: 0;
                                            display: inline-flex;
                                            width: 100%;

                                            &.service-item {
                                                a {
                                                    display: block;
                                                    width: 100%;
                                                    padding: 16px 25px;
                                                    color: $grey-dark-text;
                                                    font-size: font-size(body-md);
                                                    font-weight: font-weight(medium);
                                                }

                                                &.selected {
                                                    background-color: transparent;

                                                    a {
                                                        color: $color-brand-white;
                                                        background-color: $red;
                                                    }

                                                    &:after {
                                                        top: 14px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .sector-tab-view {
                                        .service-content {
                                            h4 {
                                                color: $cyan;
                                                border-bottom: 1px solid $grey-border-alt;
                                                padding-bottom: 16px;

                                                a {
                                                    color: $cyan;
                                                    transition: color .25s ease-in, background-color .25s ease-in;
                                                    text-transform: none;

                                                    &:hover {
                                                        color: $grey-dark;
                                                        text-decoration: none;
                                                    }
                                                }
                                            }

                                            .service-text {
                                                a {
                                                    padding: 16px 16px 16px 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.bbt-insights-nav {
                        .dd-content {
                            width: 670px;

                            .bbt-search {
                                @include make-col(12);
                                margin: 0 0 30px;
                            }

                            .dd-content-column-group {
                                @include make-row();
                                margin: 0;

                                .dd-content-column {
                                    display: inline-block;
                                    padding: 0 30px 0 0;

                                    a {
                                        display: block;
                                        margin-bottom: 20px;
                                        color: $cyan;
                                        font-size: font-size(body-md);
                                        font-weight: font-weight(bold);
                                        text-transform: none;
                                    }

                                    &:last-child {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .bbt-search {
                @include make-col-ready();
                @include make-col(2.5);
                margin: 25px 0 0;
                padding: 0;
            }
        }
    }

    .bbt-mobile {
        display: none;
        height: 60px;
        width: 60px;

        @include max-screen(md) {
            display: block;
        }

        #wrapper {
            @include make-row();
            position: absolute;
            width: 100%;
            height: 62px;
            overflow: hidden;
            z-index: 100;

            .bbt-logo {
                position: relative;
                left: 50%;
                transform: translate(-50%, 25%);
                width: 127px;
                height: 38px;

                img {
                    max-width: 125%;
                }
            }

            &.overlay {
                background-color: $hero-filter;
                width: 100%;
                height: 100vh;
                min-height: 670px;

                .bbt-logo {
                    opacity: 0.5;
                }
            }

            >.menu-checkbox {
                +.menu {
                    >label {
                        background: #fff url(https://www.bakerbotts.com/assets/src/images/nav-menu.png) 50% 50%/25px 25px no-repeat;

                        >i.fa-times {
                            display: none;
                            font-size: 36px;
                        }
                    }
                }

                &:checked {
                    +.menu {
                        >label.menu-toggle {
                            background-color: transparent;
                            background-image: none;
                            padding-top: 10px;
                            text-indent: 9px;
                            color: $color-brand-white;

                            >i.fa-times {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        label {
            cursor: pointer;
        }

        label:focus {
            outline: none;
        }

        .menu {
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            width: 295px;
            height: 100vh;
            transform: translate3d(-295px, 0, 0);
            transition: transform 0.35s;
            z-index: 30;

            > ul {
                height: 100vh;
            }

             ul {
                @include remove-list-format();
                padding: 25px;

                li {
                    width: 100%;
                    margin-bottom: 20px;

                    &.sperator {
                        width: 70%;
                        border-bottom: 1px solid $color-brand-white;
                    }

                    .menu {
                        width: 285px;
                        box-shadow: 1px 0 4px 0 rgba(83, 83, 83, 0.5);
                        overflow: auto;
                        z-index: 40;

                        label.menu-toggle {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 24px;
                            line-height: 1;
                            display: block;
                            padding: 25px;
                            text-indent: 0;
                            color: $grey-dark;
                            font-size: 24px;
                        }

                        h2 {
                            margin: 90px 25px 0;

                            a {
                                @include heading-two();
                                color: $cyan;
                            }
                        }

                        h3 {
                            margin: 40px 25px 24px;
                        }

                        ul {
                            padding-top: 20px;

                            &.sub-nav-group {
                                height: auto;
                                padding: 0 25px;
                            }

                            li {
                                margin-bottom: 15px;

                                a,
                                label {
                                    color: $cyan;
                                    font-size: font-size(body-base);
                                    font-weight: font-weight(bold);
                                }

                                .bbt-letter-grid {
                                    ul {
                                        @include make-row();
                                        padding-left: 0;
                                        justify-content: space-between;

                                        li {
                                            @include make-col-ready();
                                            @include make-col(2.25);

                                            a {
                                                font-size: 24px;
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .bbt-office-group {
                            ul {
                                padding: 0 25px;
                            }
                        }

                        .bbt-service-group {
                            h3 {
                                margin: 40px 0 24px
                            }

                            >ul {
                                padding: 0;
                            }
                        }

                        .menu {
                            width: 275px;
                        }
                    }
                }
            }

            >ul.bbt-nav-root {
                background-color: $red-dark;
                position: relative;

                >li {

                    a,
                    >label {
                        position: relative;
                        color: $color-brand-white;
                        font-size: font-size(body-md);
                        text-transform: uppercase;
                        margin-bottom: 0;

                        >i.fa-angle-right {
                            float: right;
                            right: 0;
                        }
                    }

                    &.bbt-social {
                        position: absolute;
                        bottom: 0;

                        a {
                            display: inline-block;
                            margin-right: 30px;
                            color: $color-brand-white;
                            font-size: 18px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .menu label.menu-toggle {
            position: absolute;
            right: -60px;
            width: 60px;
            height: 60px;
            line-height: 0px;
            display: block;
            padding: 0;
            text-indent: -9999px;
        }

        .menu ul li>label {
            width: 100%;
        }

        /* hide inputs */
        .menu-checkbox {
            display: none;
        }

        /* hide navigation icon for sublabels */
        .menu .menu label.menu-toggle {
            background: none;
        }

        /* fade in checked menu */
        .menu-checkbox:checked+.menu {
            transform: translate3d(0, 0, 0);
        }
    }
}