.bbt-services-search {
    @include make-row();
    padding: spacing(xxl)-3 spacing(xxl)+2 0;

    @include max-screen(md) {
        padding: spacing(xxl)-3 spacing(lg)+2 0;
    }

    @include max-screen(sm) {
        margin-top: spacing(lg)-8;
        margin-bottom: spacing(lg);
        padding: 0 spacing(lg)+2;
    }

    .bbt-services-search-box {
        @include make-col-ready;
        @include make-col(3);
        @include max-screen(sm) {
            @include make-col(12);
        }
        padding: 0;
        .keyword-search {
            margin: 0;
            padding: 0;
            position: relative;

            input {
                width: 100%;
                border: 1px solid $grey-border;
                padding: 12px 60px 12px 15px;
            }

            button {
                @include remove-button-styles();

                position: absolute;
                right: 10px;
                top: 10px;
                background-color: $color-brand-white;
                font-size: 20px;

                .fa-search {
                    color: $grey-dark-text;
                }
            }


            //Placeholder shim for IE9;
            .placeholder {
                height: auto !important;
            }
        }
    }

    + .global-main-content.content-wrapper {
        padding : 40px 37px 0;
        @include max-screen(sm) {
            padding: 20px 32px 0;
        }
    }
}
.bbt-search {
    .bbt-search-box {
        padding: 0;

        .keyword-search {
            margin: 0;
            padding: 0;
            position: relative;

            input {
                width: 100%;
                border: 1px solid $grey-border;
                padding: 11px 60px 11px 15px;
            }

            button {
                @include remove-button-styles();
                position: absolute;
                right: 10px;
                top: 10px;
                background-color: $color-brand-white;
                font-size: 20px;

                .fa-search {
                    color: $grey-dark-text;
                }
            }

            //Placeholder shim for IE9;
            .placeholder {
                height: auto !important;
            }
        }
    }
}