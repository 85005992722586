﻿/* -----------------------------------------------------------------------------
Import BASE
----------------------------------------------------------------------------- */

@import "variables";
@import "mixins";

html {
    min-height: 100%;
}

body {
    min-height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font: {
        family: $font-family-regular;
        size: 14px;
    } // background: $wh-gray-light;
}

a.bbt-view-more {
    color: $cyan;
    font-size: font-size(body-lg);
    font-weight: font-weight(bold);
    i {
        position: relative;
        top: 2px;
    }
}

p,
ul {}

h1,
h2,
h3,
h4,
h5,
h6 {}

h1 {
    @include heading-one();
}

h2 {
    @include heading-two();
}

h3 {
    @include heading-three();
}

h4 {
    @include heading-four();
}
.container-fluid {
    max-width: 1440px;
}

.bbt-main {
    @include make-row();
    padding: spacing(xxl)-3 spacing(xxl)+2 spacing(xxl);
    @include max-screen(md) {
        padding: spacing(xxl)-3 spacing(lg)+2 spacing(xxl);
    }
    @include max-screen(sm) {
     padding: 0 spacing(lg)+2;
     flex-direction: column;
    }
}