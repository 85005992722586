.person-results-details {
    text-align: left;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 16px;
    border-top: 1px solid #ffffff;
    min-height: 50px;
    transition: min-height .2s ease-in;

    a {
        text-decoration: none;
        color: #0047BB;

        &:hover {
            color: red;
        }
    }

    .contactInfo {
        color: #0047BB;
        font-size: 14px;
    }
}


