#bbt-carousel {
    @include make-row();
    position: relative;

    @include max-screen(sm) {
        position: relative;
        padding-bottom: 35%;
    }

    @include max-screen(xs) {
        padding-bottom: 75%;
    }

    span.fa {
        width: 12px;
        height: 32px;
        font-size: 32px;

        @include max-screen(sm) {
            display: none;
        }
    }

    &.carousel {
        background-color: transparent;
        position: relative;
        z-index: 10;
        padding-right: 0;
        border-top: 0;
        overflow: visible;
    }

    .carousel-indicators {
        li {
            width: 10px;
            height: 10px;
            border: 0;
            border-radius: 50%;
            background-color: $color-brand-white;

            &.active {
                background-color: transparent;
                border: 1px solid $color-brand-white;
            }
        }

        @include max-screen(sm) {
            bottom: 0;

            li {
                background-color: $color-brand-black;

                &.active {
                    border: 1px solid $color-brand-black;
                }
            }
        }
    }

    .carousel-inner {
        border-bottom: 10px solid $red;

        .carousel-item {
            .image-container {
                img {
                    display: block;
                    width: 100vw;
                    max-height: 380px;
                    height: 100vh;
                    object-fit: cover;
                    position: relative;
                    right: 0;

                    @include max-screen(sm) {
                        height: 20vh;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 67%);
                }
            }

            .video-btn {
                @include remove-button-styles();
                font-size: 73px;
                color: $color-brand-white;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -40%);
                z-index: 15;

                @include max-screen(md) {
                    top: 25%;
                    left: 50%;
                    transform: translate(-50%, -25%);
                }

                @include max-screen(sm) {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &:hover {
                    text-decoration: none;
                }
            }

            .carousel-caption {
                @include make-col(6.5);
                text-align: left;
                font-size: 18px;

                h1 {
                    font-family: $font-family-serif;

                    &:before {
                        display: inline-block;
                        content: '';
                        width: 24px;
                        height: 24px;
                        margin-right: 11px;
                        background-color: $red;
                    }
                }

                p {
                    margin-bottom: 10px;
                }

                a {
                    .fa {
                        position: relative;
                        top: 1px;
                        right: -8px;
                        font-size: 18px;
                    }
                }

                @include max-screen(sm) {
                    @include make-col(12);
                    position: absolute;
                    right: 32px;
                    left: 32px;
                    top: 110%;

                    h1 {
                        color: $color-brand-black;
                        font-size: 1.2rem;
                        max-width: 50ch;
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    p {
                        color: $color-brand-black;
                        font-size: 1rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 130ch;
                    }

                    a {
                        font-size: 1rem;
                    }
                }

                @include max-screen(xs) {
                    h1 {
                        font-size: 1.2rem;
                        max-width: 50ch;
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    p, a {
                        font-size: .9rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 130ch;
                    }
                }
            }
        }

        @include max-screen(sm) {
            overflow: visible;
        }
    }
}