.bbt-sitemap-section {
    background-color: $grey-light;
}
.bbt-sitemap {
    @include make-row();
    max-width: 1400px;
    margin: 0 auto;
    padding: 60px 52px;
    background-color: $grey-light;
    position: relative;
    z-index: 10;

    .bbt-sitemap-column {
        @include make-col-ready();
        @include make-col(2);
        h3 {
            margin-bottom: spacing(md);
            @include heading-three;
        }
        h4 {
            margin-bottom: spacing(sm)-3;
            @include heading-four;
        }
        ul {
            @include remove-list-format();
            li {
                margin-bottom: spacing(sm)-2;
                a {
                    color: $cyan !important;
                    font-size: font-size(body-md);
                    font-weight: font-weight(bold);
                }
                &:last-child {
                    margin-bottom: spacing(md);
                }
            }
        }
        .bbt-letter-grid {
            ul {
                @include make-row();
                margin: 0 0 20px -15px;
                padding-left: spacing(xxs);
                li {
                    @include make-col-ready();
                    @include make-col(1);
                    margin-bottom: spacing(xs);
                    padding: 0 spacing(xs);
                    a {
                        text-transform: uppercase;
                    }
                }
            }
        }
        &:first-child{
            padding-left: 0;
        }
        @include max-screen(md) {
            @include make-col(3);
            .bbt-letter-grid {
                ul {
                    @include make-col(12);
                }
            }
        }
        @include max-screen(sm) {
            @include make-col(12);
            padding-left: 0;
            .bbt-letter-grid {
                ul {
                    @include make-col(9);
                }
            }
        }
    }
    @include max-screen(sm){
        padding: 60px 32px;
    }
}