// bio profile card

.updated-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: $darkblue-update;
    color: white;
    padding: 20px 64px;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    font-family: poppins;

    .page-tools {
        display: flex;
        gap: 20px;
        right: 80px;
        width: max-content
    }

    .page-tools .pagetool-share-links::before {
        border-bottom: 20px solid $color-brand-white;
    }

    @media (max-width: 768px) {
        display: flex;
        height: max-content;
        padding: 20px 89px 20px 16px;
        align-items: center;
    }
}

.services-side-nav {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-right: 42px;
    top: 25%;
    z-index: 9;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
    padding-right: 280px;
    @media (max-width: 768px) {
        height: initial;
    }
}

.bio-profile-card {
    color: $color-brand-white;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    background: linear-gradient(180deg, #E5E1E6 50%, #C0C3D8 100%); 
}

.small-bio-banner {
    display: none;
}

.bio-banner {
    background-position: center;
    transition: .2s ease;
    width: 100%;
    height: 290px;
    padding-top: 20px;

    @media (max-width:768px) {
        height: 570px;
        position: relative;
    }
}

.small-bio-banner {
    background-position: center;
    transition: .2s ease;
    width: 100%;
    height: 194px;
    padding: 20px 131px 20px 221px;

    @media (max-width:768px) {
        height: 130px;
        padding: 0;
        display: none;
        align-items: center;
        justify-content: center;
    }
}

.bio-card-content {
    display: flex;
    flex-direction: row;
    width: 75%;
    background: rgba(0, 0, 0, 0.80);
    height: 251px;
    margin: auto;
    

    @media (max-width:768px) {
        width: 100%;
        height: 78%;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: 120px;
    }
}

.small-bio-card-content {
    height: 154px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    position: relative;
    background: rgba(0, 0, 0, 0.80);

    @media (max-width:768px) {
        flex-direction: row;
        height: 90px;
        justify-content: center;
    }
}

.bio-card-profile-image {

    > img {
        width: 251px;
        height: 251px;
    }

    @media (max-width:768px) {
        position: relative;
        top: -115px;
        > img {
            width: 251px;
            height: 251px;
        }
    }
}

.small-bio-card-profile-image {

    > img {
        width: 145px;
        height: 154px;
    }

    @media (max-width:768px) {
        > img {
            width: 71px;
            height: 76px;
        }
    }
}

.bio-card-profile-info {
    padding-left: 35px;
    padding-top: 20px;
    display: grid;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        position: relative;
        top: -115px;
    }
}

.small-bio-card-profile-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding-left: 30px;

    @media (max-width: 768px) {
        align-items: flex-start;
        padding-left: 16px;
        gap: 3px;
    }
}

.bio-card-name {
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.bio-card-title {
    font-size: 16px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.bio-card-email-content {
    @media (max-width:768px) {
        margin-bottom: 0px;
    }
}

.bio-card-email {
    font-size: 16px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
    &:hover {
        color: $blue;
    }
}

.bio-card-contact {
    font-size: 16px;
    display: inline-flex;
    align-items: flex-start;
    gap: 32px;

    @media (max-width:768px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}

a {
    color: $color-brand-white;
}

.bio-card-contact-links {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 40px;
    padding-top: 10px;
    font-size: 14px;

    @media (max-width:768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}

.bio-card-contact-link-pdf {
    background: $color-brand-white;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    color: $darkblue-update;


    i {
        font-size: 14px;
        color: $darkblue-update;
    }

    @media (max-width:768px) {
        display: inline-block;
        padding: 12px 49px 12px 49px;
        text-align: center;
    }
}


.pdf-download-icon {
    filter: invert(100%) sepia(100%) saturate(10000%) hue-rotate(214deg) brightness(100%) contrast(100%);
    height: 18px;
}

.bio-card-contact-link-linkedin {
    background: $color-brand-white;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;

    @media(max-width: 768px) {
        padding: 15px 70px 15px 70px;
    }
}

//bio details

.bio-details {
    border: none;
    @media (min-width: 768px) {
        padding-inline-start: 35px;
        padding-bottom: 36px;
    }
    > div {
        padding-bottom: 30px;
    }

    @media(max-width: 768px) {
        padding: 20px 16px 52px 16px;
        width: 100%;
    }
    .rich-text p {
        overflow: initial;
    }
}

.bio-details-content {
    display: flex;
    justify-content: space-between;
    padding-left: 70px;
    padding-right: 70px;
    gap: 40px;

    @media(max-width: 768px) {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        gap: 10px;
        align-items: center;
        hr {
            display: none;
        }
    }
    hr {
        display: block;
    }
}

.bio-details-holder {
    display: flex;
    flex-direction: column;
}

.bio-details-navaccordion {
    background: transparent;
    border: none;
}

.navaccordion-active {
    border-left: 2px solid rgba(64, 64, 64, 0.50);
}

.bio-details-tabs {
    font-weight: 600;
    line-height: 100%;
    width: 100%;
    position: -webkit-sticky;
    position: initial;
    top: 0;
    background-color: white;

    @media(min-width: 768px) {
        position: sticky;
    }
}

.bio-details-tabs.Left-Nav-link{
    display: none;
    @media(min-width: 768px) {
        display: block;
    }
}

.service-detail-dropdown {
    height: 900px;
    padding-left: 16px;
    padding-right: 116px;
    @media(max-width: 768px) {
        height: initial;
    }
}

.bio-details-text {
    padding-bottom: 20px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 12px;
    letter-spacing: 1px;
}

.bio-details-nav {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
    border-left: 2px solid rgba(64, 64, 64, 0.50);
    padding-left: 0px;
    padding-bottom: 0px;
    padding-top: 0px;



    @media(max-width: 768px) {
        display: none;
        overflow: hidden;
        transition: max-height .2s ease-out;
        max-height: 0px;
    }
}

.bio-details-navlink {
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
    color: black;
    padding-left: 20px;
    border-left: 7px solid transparent;

}
.linkhoveractive {
    color: $darkblue-update;
    cursor: pointer;
    text-decoration: none;
    border-left: 7px solid $darkblue-update;
}

.bio-details-navlink:hover , .navlink-selected {
    color: $darkblue-update;
    cursor: pointer;
    text-decoration: none;
    border-left: 7px solid $darkblue-update;
}

.bio-details-title {
    font-size: 32px;
    line-height: 140%;
    color: black;
    margin-bottom: 20px;
}

.bio-details-textblurb {
    max-height: 710px;
    overflow: hidden;
    transition: max-height 0.7s;

    > ul {
        list-style-type: disc;
    }
}

.overview-section {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;

    > blockquote {
        font-family: poppins;
    }

    > blockquote p {
        color: $darkblue-update;
    }

    > blockquote em {
        color: black;
    }

    a {
        color: $darkblue-update;
    }
}

.bio-details-textblurb .open {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    max-height: 5000px;
    transition: max-height 0.7s;
    width: 20%;

    > ul {
        list-style-type: disc;
    }

    > blockquote p {
        color: $darkblue-update;
    }

    > blockquote em {
        color: black;
    }
}

.showmorebackground {
    background: linear-gradient(to bottom, transparent, white);
    bottom: 40px;
    text-align: center;
    width: 100%;
    position: relative;
}

.show-more {
    width: 150px;
    height: 50px;
    background-color: white;
    border: 3px solid $darkblue-update;
    border-radius: 1000px;
    display: block;
    cursor: pointer;
    text-align: center;
    padding: 8px;
    margin: 5px auto;
    color: $darkblue-update !important;
    font-size: 20px;
    font-weight: 600;

    @media(max-width: 768px) {
        //width: 50%;
    }
}

.services-dropdown {
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.services-dropdown-accordion {
    font-family: poppins;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    background-color: $darkblue-update;
    color: white;
    width: 50%;
    border: none;
    text-align: left;
    outline: none;
    transition: .4s;
    border-bottom: 1px solid #FFF;
    line-height: 140%;
    padding: 20px 10px;
}

.services-dropdown-accordion::after {
    content: '\02795';
    color: white;
    float: right;
}


.services-dropdown-accordion-panel {
    overflow: hidden;
    background-color: white;
    padding-left: 30px;
    max-height: 100%;
    overflow: hidden;
    transition: max-height .2s ease-out;
    display: none;
    flex-direction: column;

    > a {
        color: black;
        text-decoration: none;
    }
}

.page-tools {
    .page-tools-item {
        background-color: transparent;
    }
}

.qualificationsColumns {
    @media (max-width: 768px) {
        column-count: 1;
    }
}

.mobile-side-nav-accordion {
    display: none;
   
}

#mobile-nav-button {
    background: #fff;
}

.related-accordion-btn .servicesicon {
    color: white;
    min-width: 18px;
    float: right;
    font-size: 18px;
    border-left: white 1px solid;
    padding-left: 20px;
    font-style: normal;

    @media (max-width: 768px) {
        float: left;
        border: none;
        color: black;
        padding-left: 0px;
        padding-right: 20px;
    }
}

.related-accordion-btn .servicesicon::after {
    content: '+';
}

.related-accordion-btn.is-active .servicesicon::after {
    content: '-';
}

.people-side-content {
    font-size: 18px;
    
    @media (min-width: 768px) {
        width: 280px;
    }
}

#mobile-side-content {
    display: none;
}
#desktop-side-content {
    display: block;
}


@media screen and (max-width: 768px) {
    #desktop-side-nav {
        display: none;
    }

    .services-side-nav {
        width: 100%;
        transition: .4s ease;
        z-index: 20;
        top: 40px;
        padding-left: 16px;
        padding-right: 16px;
        gap: 10px;
    }

    .mobile-side-nav-accordion {
        display: block;
        top: 0px !important;
        background: white;

        > a {
            font-size: 18px;
            font-weight: 600;
        }

        .related-accordion-btn {
            background: #fff;
            color: #000 !important;
            border: 1px solid #ECECEC;
            &:hover {
                background: #fff !important;
                color: #000 !important;
            }
        }

        .related-accordion-btn .servicesicon::after {
            content: url(../../../../../UI/src/assets/img/caret-down.svg);
        }

        .related-accordion-btn.is-active .servicesicon::after {
            content: url(../../../../../UI/src/assets/img/caret-up.svg);
        }


        .related-accordion-btn.is-active {
            background: #fff !important;
            color: #000 !important;
        }
    }

    .people-side-content {

        .related-accordion-btn .servicesicon {
            font-style: normal;
            color: white;
            min-width: 18px;
            float: right;
            font-size: 18px;
            border-left: white 1px solid;
            padding-left: 20px;
            padding-right: 0px;
        }
    }

    #mobile-side-content {
        display: block;
        width: 100%;
    }
    #desktop-side-content {
        display: none;
    }
}

#bio-details-overview{
   padding-top: 20px;
    @media (min-width: 768px) {
        padding-top: 62px;
    }
}
#bio-banner-banner-info{
    z-index: 11 !important;
}
#bio-details-qualifications{
    .qualifications-admaff{
        column-count: 2;
        @media (max-width: 768px) {
            column-count: initial;
        }
    }
    .qualifications-edu{
        column-count: 2;
        @media (max-width: 768px) {
            column-count: initial;
        }
    }
}

