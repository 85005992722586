﻿// COLORS =============================================== *
//featured-banner
$blue: #1e90ff; 
$white: #ffffff;
$lightblue: #ADD8E6;
$beige: #F5F5DC;
$darkblue: #00008B;
$fa-var-arrow-circle-right: "\f0a9";
$fa-var-arrow-circle-o-right: "\f18e";
$fa-var-arrow-right: "\f061";
$arrow-right-long: "\f178";
$grey-new: #E5E1E6;
$red-new: #10242F;
$sky: #007bff;
$bullet-color: #C4C4C4;
$placeholder-color: #B5B5B5;

//homepage update colors
$red-update: #ce1126;
$lightblue-update: #6cace4;
$light-background-update: #e5e1e6;
$darkblue-update: #0047BB;
$shodow-grey1: #898B9D;

//colors
$color-brand-black: #000;
$color-brand-black-dull: #171717;
$color-brand-white: #fff;


//greys
$grey: #d4d4d4;
$grey-alt: #a0a0a0;
$greyer: #4a4a4a;

$grey-dark: #393939;
$grey-darker: #404040;
$grey-darker-tint: #707070;
$grey-dark-alt: #6b6b6b;
$grey-dark-text: #616161;
$grey-dark-alt-text: #636363;
$grey-darkest: #8b8b8b;
$grey-darkest-tint: #424242;
$grey-darkest-alt: #959595;

$grey-border: #cccccc;
$grey-border-alt: #979797;
$grey-border-al2: #D8D8D8;

$grey-light: #f9f9f9;
$grey-light-tint: #d7d7d7;
$grey-light-tinted: #a7a7a7;
$grey-light-alt: #c5c5c5;
$grey-lighter: #e1e1e1;
$grey-light-shade: #ededed;
$grey-med-shade: #999;

//Red
$red: #ce1127;
$red-dark: #b20e22;
$red-alt: #d92833;
//cyan
$cyan: #37a09c;
$cyan-alt: #37a09c;


//filters
$opaquewhite: rgba(255, 255, 255, 0.5);
$opaquewhite-alt: rgba(255, 255, 255, 0.95);
$opaqueblack: rgba(0, 0, 0, 0.8);
$site-opaque-bg: rgba(0, 0, 0, 0.5);
$global-hero-opaque-bg: rgba(0, 0, 0, 0.65);
$hero-filter: rgba(47, 47, 47, 0.7);

// TYPOGPRAHY =============================================== *
$font-family-regular: Poppins !important;
$font-family-serif: Poppins !important;

$font-weights: ( bold: 700, medium: 500, regular: 300, light: 100);

$font-sizes: ( body-base: 14px,
body-sm: 12px,
body-md: 16px,
body-lg: 18px,
tile-lg: 35px,
page-title: 42px,
service-nav: 20px,
heading-one: 32px,
heading-two: 28px,
heading-three: 21px,
heading-four: 18px,
heading-five: 16px,
heading-six: 14px);

// SPACING =============================================== *
$spacing: ( xxs: 5px,
            xs: 10px,
            sm: 15px,
            md: 20px,
            lg: 30px,
            xl: 40px,
            xxl: 50px,
            grid-gutter-half: 15px,
            grid-gutter: 30px
);

// Z-INDEX =============================================== *
$z-index: ();

// BREAKPOINTS (Media Queries) =============================================== *
//based on min width, default breakpoints
$breakpoints: (xxs: 380px,
                xs: 480px,
                sm: 768px,
                md: 992px,
                lg: 1200px,
                xl: 1360px
);

//if max width breakpoints are needed
$max-breakpoints: (xxs: 380px,
                xs: 480px,
                sm: 768px,
                md: 992px,
                lg: 1200px,
                xl: 1360px
);
