.modal {
        .modal-dialog {
            max-width: 77%;
            margin: 30px auto;
        }

    .modal-content {
        border: 0;
        border-radius: 0;
        background-color: transparent;
        .modal-body {
            padding: 0px;
        }
    }

    .modal-backdrop {
        &.show {
            opacity: 0.7;
        }
    }

    .close {
        width: 29px;
        height: 36px;
        position: absolute;
        right: -36px;
        top: -29px;
        z-index: 999;
        font-size: 32px;
        font-weight: normal;
        color: #fff;
        opacity: 1;
    }
}