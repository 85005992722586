.bbt-info {
    &.bbt-mobile {
        display: none;
    }

    @include make-col-ready();
    @include make-col(9.5);
    display: flex;
    padding-left: 0;

    .bbt-info-column {
        @include make-col-ready();
        @include make-col(4);

        &:first-child {
            padding-left: 0;
        }

        .bbt-news-column-header {
            margin-bottom: spacing(xs)+3;
            h2 {
                a {
                    display: block;
                    width: 100%;
                    margin-right: spacing(xxs);
                    color: $grey-dark;
                    transition: color .25s ease-in, background-color .25s ease-in;
                    position: relative;
                    white-space: nowrap;
                    font-size: clamp(15px, 1.9vw, 28px);
                    &:hover {
                        color: $cyan;
                        text-decoration: none;
                    }
                    i {
                        color: $cyan;
                        position: relative;
                        top: 1px;
                        right: -8px;
                    }
                }
            }
        }

        @include max-screen(md) {
            @include make-col(4);
        }

        @include max-screen(sm) {
            @include make-col(12);
        }
    }

    .bbt-news-tile {
        border: 1px solid $grey-border;
        border-bottom: 10px solid $red;
        margin-bottom: spacing(lg);

        .bbt-news-image {
            width: 100%;

            img {
                max-width: 100%;
            }
        }

        .bbt-news-tile-content {
            padding: spacing(xl) spacing(md);

            .bbt-news-type {
                color: $grey-dark;
                font-size: font-size(body-lg);
                font-weight: font-weight(bold);
                margin-bottom: spacing(md);
            }

            .bbt-news-date {
                margin-bottom: spacing(xxs)+3;
                color: $grey-dark;
                font-size: font-size(body-lg);
                font-weight: font-weight(bold);
            }

            .bbt-news-title {
                a {
                    color: $grey-dark-text;
                    font-size: font-size(body-lg);
                    font-weight: font-weight(bold);
                    transition: color .25s ease-in, background-color .25s ease-in;

                    &:hover {
                        color: $cyan;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @include max-screen(md) {
        @include make-col(9);
    }

    @include max-screen(sm) {
        &.bbt-desktop {
            display: none;
        }

        &.bbt-mobile {
            display: block;

            .card-header {
                margin: 0 -32px;
                background-color: $grey-lighter;

                h4 {
                    .btn {
                        display: inline-block;
                        @include heading-four();
                        color: $cyan;
                        padding-right: spacing(xs);
                        text-decoration: none;

                        &.collapsed {
                            +.accordion-indicator {
                                top: 0;
                                transform: rotate(0deg);
                            }
                        }
                    }

                    .accordion-indicator {
                        display: inline-block;
                        transform: rotate(180deg);
                        position: relative;
                        top: 5px;

                        &:after {
                            content: url('../img/triangle.png');
                        }
                    }
                }
            }

            .card-body {
                padding: 0;

                .bbt-news-tile {
                    &:first-child {
                        margin-top: spacing(md)+2;
                    }
                }
            }
        }

        @include make-col(12);
        display: block;
        padding-right: 0;
    }
}