
.bbt-news-section {
    font-family: Poppins;
    &_title{
        padding: 40px 0 40px 0;
        display: flex;
        flex-direction: column;
        @media(min-width:768px) {
            flex-direction: row;
        }
        &-left{
            flex: 50%;
            color: $color-brand-black;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 44.8px */
            display: inline-flex;
            align-items: center;
        }
        &-right{
            flex: 50%;
            color: $darkblue-update;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 31.2px */
            text-transform: capitalize;
            display: inline-flex;
            align-items: center;
            gap: 32px;
            padding-top: 10px;
            a{
                color: $darkblue-update;
                &:hover {
                    text-decoration: none;
                }
            }

            @media (min-width:768px) {
                padding-top: 0;
                justify-content: right;
            }

            &_link{
                display: flex;
                flex-direction: row;
                @media (min-width:768px) {
                    justify-content: flex-end;
                }
                justify-content: flex-start;

                .bbt-news-section_title-right_word{
                    width: 70%;
                }
                .bbt-news-section_title-right_icon {
                    width: 10%;
                }
            }
        }
    }
    &_content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        &-item {
            border-top: 3px solid $darkblue-update;
            height : auto;
            margin: 0 20px 20px 0;
            padding-top: 20px;
            @media(min-width: 768px){
                width:calc(100% / 3.3);
                margin: 0;
            }
            &_date, &_category {
                color: $color-brand-black;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 16.8px */
                margin-right: 10px;
            }
            &_title{
                display: block;
                overflow: hidden;
                color: $color-brand-black;
                text-overflow: ellipsis;
                font-family: Poppins;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-decoration-line: none;
                margin: 15px 0 15px 0;
                width: 100%;
                @media(min-width: 768px){
                    width:73%;
                    font-size: 20px;
                }
            }
            img{
                max-width:100%;
                max-height:100%;
                display: block;
                height: 180px;
                width: 100%;
                object-fit: cover;
                margin-bottom: 10px;
            }
            .bbt-recognition_img{
                img {
                    width: initial;
                }
               
            }
        }
        &-people{
            margin-bottom: 16px;
            margin-right: 20px;
            @media(min-width: 768px){
               margin-right: 20x;
            }
            
            &-img{

            }
            &-b{
                display: flex;
                flex-direction: column;
                color: $color-brand-black;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 25.2px */
                margin-top: 16px;
                margin-bottom: 16px;
                width: 200px;
                &_name{
                    color:$color-brand-black;
                    &:hover {
                        text-decoration: none;
                        color: $color-brand-black;
                    }
                }
                &_pos{
                    font-weight: 400;
                    width: 80%;
                    margin-top: 5px;
                }
                &_email{
                    color: $darkblue-update;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
    &_content-small {
        @media(min-width: 768px){
            display: none;
         }

    }
    &_Experience-list{
        &-item {
            h3{
                display: block;      
                a{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    color: $color-brand-black;
                    font-family: Poppins;
                     font-size: 18px;
                     font-style: normal;
                     font-weight: 600;
                     line-height: 140%; /* 25.2px */

                     &:hover {
                        text-decoration: none;
                     }

                }
            }
            time {
                color: $color-brand-black;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 16.8px */
                margin-bottom: 10px;
            }
          


        }
    }
    .slick-next {
        right: 15px;
        font-size: 12px;
        color: black;
        &:hover {
            color: black;
        }
    }
    
.slick-prev {
    left: 15px;
    font-size: 12px;
        color: black;
        &:hover {
            color: black;
        }
}

.ideas-carousel-dots, .news-carousel-dots, .service-professional-dots, .services-recognition-dots {
    display: block;
    .slick-dots li {
        margin: 0 8px !important;
    }
    .slick-dots li button:before{
        width: 8px !important;
        height: 8px !important;
        border: 2px solid $color-brand-black !important;
    }
    .slick-dots li.slick-active button:before{
        background-color: $color-brand-black;
    }
    @media(min-width: 768px){
        display: none;
     }
}
}
