.bbt-recentnews {
    &.bbt-mobile {
        display: none;
    }

    @include make-col-ready();
    @include make-col(2.5);
    border-left: 1px solid $grey-light-alt;
    padding-left: spacing(lg);

    h2 {
        a {
            display: block;
            width: 100%;
            margin-right: spacing(xxs);
            color: $grey-dark;
            transition: color .25s ease-in, background-color .25s ease-in;
            position: relative;

            &:hover {
                color: $cyan;
                text-decoration: none;
            }

            i {
                color: $cyan;
                position: relative;
                top: 1px;
                right: -8px;
            }
        }
    }

    ul {
        @include remove-list-format();

        li {
            margin-top: spacing(lg);

            .bbt-news-title {
                a {
                    color: $grey-dark;
                    font-size: font-size(body-lg);
                    font-weight: font-weight(bold);
                    transition: color .25s ease-in, background-color .25s ease-in;

                    &:hover {
                        color: $cyan;
                        text-decoration: none;
                    }
                }

                margin-bottom: spacing(xxs);
            }

            .bbt-news-provider {
                margin-bottom: spacing(xxs);
                color: $grey-alt;
                font-size: font-size(body-base);
                font-weight: font-weight(medium);
            }

            .bbt-news-date {
                color: $grey-dark;
                font-size: font-size(body-lg);
                font-weight: font-weight(bold);
                margin-bottom: 0;
            }
        }
    }

    @include max-screen(md) {
        @include make-col(3);
    }

    @include max-screen(sm) {
        &.bbt-desktop {
            display: none;
        }

        &.bbt-mobile {
            display: block;

            .card-header {
                margin: 0 -32px;
                background-color: $grey-lighter;

                h4 {
                    .btn {
                        display: inline-block;
                        @include heading-four();
                        color: $cyan;
                        padding-right: spacing(xs);
                        text-decoration: none;

                        &.collapsed {
                            +.accordion-indicator {
                                top: 0;
                                transform: rotate(0deg);
                            }
                        }
                    }

                    .accordion-indicator {
                        display: inline-block;
                        transform: rotate(180deg);
                        position: relative;
                        top: 5px;

                        &:after {
                            content: url('../img/triangle.png');
                        }
                    }
                }
            }

            .card-body {
                padding: 0;

                li:last-child {
                    margin-bottom: spacing(lg);
                }
            }
        }

        @include make-col(12);
        border-left: 0;
        padding: 0;
    }
}