/* -----------------------------------------------------------------------------
Import CORE LIBS, GLOBALS
----------------------------------------------------------------------------- */

/* BOOTSTRAP ---------------------------------------------------------------- */
// Core framework variables and mixins
@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "../../../node_modules/bootstrap/scss/functions";
// @import "../../../node_modules/bootstrap/scss/variables";
// @import "../../../node_modules/bootstrap/scss/mixins";
// @import "../../../node_modules/bootstrap/scss/reboot";
// @import "../../../node_modules/bootstrap/scss/utilities";
// @import "../../../node_modules/bootstrap/scss/grid";
// @import "../../../node_modules/bootstrap/scss/modal";
// @import "../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../node_modules/bootstrap/scss/carousel";

/* FONT AWESOME ---------------------------------------------------------------- */
// @import "font-awesome/font-awesome";

/* GLOBALS ---------------------------------------------------------------- */
@import "globals/variables";
@import "globals/mixins";
@import "globals/functions";
@import "globals/fonts";
@import "globals/base";
