.bbt-infocallout {
    // @include make-col-ready();
    @include make-col(3);
    padding: 0;

    @include max-screen(sm) {
        @include make-col-offset(0);
        @include make-col(12);
        margin-top: 0;
        order: 2;
    }

    .bbt-callout-tile {
        border: 1px solid $grey-border;
        margin-bottom: spacing(lg);

        &:last-child {
            margin-bottom: 0;
        }

        .bbt-callout-image {
            width: 100%;

            img {
                max-width: 100%;
            }
        }

        .bbt-callout-tile-content {
            padding: spacing(xl) spacing(md);

            h3 {
                a {
                    @include heading-three();
                    color: $greyer;
                    display: block;
                    width: 100%;
                    transition: color .25s ease-in, background-color .25s ease-in;
                    position: relative;
                    &:hover {
                        color: $cyan;
                        text-decoration: none;
                    }
                }
            }

            p {
                margin: spacing(md) 0;
                color: $grey-dark-text;
                font-size: font-size(body-md);
            }
        }
    }
}