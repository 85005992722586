.bbt-regionexperience {
    @include make-row();
    position: relative;

    h2 {
        width: 100%;
        text-align: center;
        @include max-screen(sm) {
            text-align: left;
            padding: 0 32px;
        }
    }

    @include max-screen(sm) {
        h2 {
            margin-top: 50px;
        }
    }

    .popover {
        width: 174px;
        margin-left: -2%;
        border: 0;
        border-radius: 0;
        padding: spacing(xs);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: $grey-light;

        .arrow {
            display: none;
        }

        h3.popover-header {
            @include heading-four();
            border-bottom: 1px solid $grey-darkest-alt;
            background-color: $grey-light;

            a {
                color: $cyan;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .popover-body {
            ul {
                @include remove-list-format();

                li {
                    a {
                        color: $cyan;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .bbt-desktop {
        @include make-col-ready();
        @include make-col(12);
        padding: 0 spacing(xxl)+2 spacing(xxl);
        text-align: center;

        @include max-screen(sm) {
            display: none;
        }

        #bbt-regionmap {

            svg {
                width: 1000px;
                height: 500px;

                .circle {
                    opacity: 0;
                    fill: #b9b9b9;
                    fill-opacity: 1;
                    stroke: black;
                    stroke-width: 0.5;
                    stroke-miterlimit: 3.97446823;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                }

                .land {
                    fill: #b9b9b9;
                    fill-opacity: 1;
                    stroke: white;
                    stroke-opacity: 1;
                    stroke-width: 0.5;
                    stroke-miterlimit: 3.97446823;
                    stroke-dasharray: none;
                }

                .coast {
                    stroke-width: 0.3;
                }

                .ocean {
                    opacity: 1;
                    color: #000000;
                    fill: white;
                    fill-opacity: 1;
                    fill-rule: nonzero;
                    stroke: #fff;
                    stroke-width: 0.5;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    marker: none;
                    marker-start: none;
                    marker-mid: none;
                    marker-end: none;
                    stroke-miterlimit: 1;
                    stroke-dasharray: none;
                    stroke-dashoffset: 0;
                    stroke-opacity: 1;
                    visibility: visible;
                    display: inline;
                    overflow: visible;
                }

                .lake {
                    fill: white;
                    fill-opacity: 1;
                    stroke: white;
                    stroke-opacity: 1;
                    stroke-width: 0.3;
                    stroke-miterlimit: 3.97446823;
                    stroke-dasharray: none;
                }
            }
        }
    }

    .bbt-mobile {
        display: none;
        @include make-col-ready();
        @include make-col(12);
        padding: 0 32px;

        @include max-screen(sm) {
            display: block;
        }

        .bbt-region-list {
            @include remove-list-format();
            @include make-col-ready();
            @include make-col(12);
            padding: 0;

            li {
                h4 {
                    margin: 40px 0 12px;
                }

                a {
                    display: block;
                    margin-bottom: 15px;
                    color: $cyan;
                    font-size: font-size(body-base);
                    font-weight: font-weight(bold);
                }
            }
        }
    }
}